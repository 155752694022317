<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="w-100">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">403</h1>
            <h4 class="pt-3">عذراً, ليس لديك الصلاحية للوصول لهذه الصفحة</h4>
          </div>
          <div>
            <router-link to="/" tag="button" active-class="btn btn-lg btn-info"> الرئيسية </router-link>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: "Page403",
};
</script>
